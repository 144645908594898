import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { LoginAuthService } from '../services/login-auth-service';

@Directive({
  selector: '[appCheckRolesToShow]',
  standalone: true,
})
export class CheckRolesToShowDirective implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<boolean>();

  private allowedRoles: Array<string> = [];

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authorizationService: LoginAuthService) { }

  @Input() set appCheckRolesToShow(allowedRoles: Array<string>) {
    this.allowedRoles = allowedRoles;
  }

  ngOnInit(): void {
    const isAuthorized = this.authorizationService.isAuthorized(this.allowedRoles);
    if (!!isAuthorized) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}

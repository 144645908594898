
export const USER_ROLE_CONSTANT = {
    ADMINISTRATOR: 'Admins',
    CREW_MEMBER: 'CrewMember',
    CREW_MEMBER_PLUS: 'CrewMemberPlus',
    CREW_SERVICES: 'CrewServices',
    INTERNAL_ADMIN: 'InternalAdmin',
    INTERNAL_REPORTS_ADMIN: 'InternalReportsAdmin',
    EXTERNAL_ADMIN: 'ExternalAdmin',
    EXTERNAL_REPORTS_ADMIN: 'ExternalReportsAdmin',
    EXTERNAL_REPORTS_USER : 'ExternalCrewcareReportsUser',
    VERTICAL_LEAD: 'VerticalLead',
    DIL_AGENT: 'DILAgent',
    TECHNICAL_SUPPORT: 'TechnicalSupport',
};

export const API_CONSTANT = {
    GET_ALL_PAGES: "page=1&size=10000"
}

export const SERVER_DATE_FORMAT_CONSTANT: string = 'yyyy-MM-DD';
export const DEFAULT_DATE_FORMAT_CONSTANT: string = 'MM-DD-YYYY';

export const PASSWORD_NOT_CHANGED_SINCE_DAYS: number = 180;

export const HTTP_REQUEST_WINDOW_TIME_CONSTANT: number = 60000; //1 minutes

export const TAXONOMY_BATH_ROOM_AMENITIES: string = 'e4dc1bff-e3e5-4d0f-97cb-1110306609df';
export const TAXONOMY_GUEST_ROOM_AMENITIES: string = '83b4084d-d923-43f6-ba5c-b9692fcfa847';
export const TAXONOMY_MORE_HOTEL_AMENITIES: string = 'f5cbcd66-e3ee-4712-81c5-c5200000b936';
export const TAXONOMY_PROPERTY_TYPE: string = 'b32158f6-9891-41db-9a30-b8f72f214ea7';
export const TAXONOMY_LOCATION: string = '0f4aa3e3-2a2c-4502-94cf-5c7c10bb18aa';
export const TAXONOMY_HOTEL_RECREATIONAL_FACILITIES: string = 'f1195659-f9fe-4ddf-b914-ceb6bc93cffa';
export const TAXONOMY_ROOM_SERVICE: string = '6223b7c8-1014-4bf2-b990-d7c69500c8fa';
export const TAXONOMY_SAFETY_SECURITY: string = '971a71e2-5257-47fe-b92f-a411f6ebd187';
export const TAXONOMY_FACILITIES_SERVICE: string = '2fb79d53-ef7b-4795-a65f-6c747d27acbe';
export const TAXONOMY_HOTEL_POLICIES: string = '9f7f47fa-f902-42bf-a7ac-48cbd5da1b22';

export const AIRLINE_ID_APIHOTELS: string = 'a5406a77-d4dd-4c30-91ca-a863e2cc8beb';
export const OTHER_HOTEL_ID: number = 2798;
export const HOTEL_TRANSPORTATION_ID: number = 45; // if selected must enter hotel id and (other hotel name if hotel id = 2798)
export const THIRD_PARTY_TRANSPORTATION_ID: number = 46; // if selected must enter otherTransportCompanyName
export const UBER_ID:number = 230;

export const FEEDBACK_TYPE_COMPLAINT: number = 0;
export const FEEDBACK_TYPE_COMPLIMENT: number = 1;

export const TRIP_DIRECTION_AIRPORT_TO_HOTEL: number = 1;
export const TRIP_DIRECTION_HOTEL_TO_AIRPORT: number = 2;
export const TRIP_DIRECTION_OTHER: number = 3;

export const FEEDBACK_CATEGORY_ID_HOTEL: number = 0;
export const FEEDBACK_CATEGORY_ID_TRANSPORT: number = 1;

export const FEEDBACK_TOPIC_COMPLIMENT_UUID :string = 'd4cdcbc3-54be-4c84-a19d-ef38d8a8a712'

export const PAGE_DROP_DOWN = [
    { id: 10, name: 10 },
    { id: 20, name: 20 },
    { id: 50, name: 50 },
    { id: 100, name: 100 }
  ];
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, shareReplay, throwError } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { HttpRequestConstants } from './http-requests-constants';
import { HTTP_REQUEST_WINDOW_TIME_CONSTANT } from '../common/constants/constant';
import { Airline, AirlinePagedResponse, AirlineResponse } from '../model/airline.model';
import { FeaturedHotelUpdateRequest } from '../model/featured-hotel.model';
import { MailListUpdateRequest } from '../model/mail-list.model';

@Injectable({
  providedIn: 'root'
})
export class AirlineService {
  error = new Subject<string>();
  constructor(private readonly httpRequestService: HttpRequestService) { }
  allAirlines$: Observable<AirlinePagedResponse> | undefined;
  userAirlines$: Observable<AirlineResponse> | undefined;;
  featuredHotels$: any;
  getCachedAirlines() {
    if (!this.allAirlines$) {
      let searchParams = new HttpParams();
      searchParams = searchParams.append('page', 1);
      searchParams = searchParams.append('size', 10000);
      searchParams = searchParams.append('isEnabled', 1);
      this.allAirlines$ = this.httpRequestService
        .getWithExplicitParams(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES, searchParams)
        .pipe(shareReplay({ bufferSize: 1, refCount: false, windowTime: HTTP_REQUEST_WINDOW_TIME_CONSTANT }));
    }
    return this.allAirlines$;
  }

  getAllAirlines() {    
      let searchParams = new HttpParams();
      searchParams = searchParams.append('page', 1);
      searchParams = searchParams.append('size', 10000);
      searchParams = searchParams.append('isEnabled', 1);
      return this.httpRequestService
        .getWithExplicitParams(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES, searchParams)
        .pipe(shareReplay({ bufferSize: 1, refCount: false, windowTime: HTTP_REQUEST_WINDOW_TIME_CONSTANT }));    
  }

  getAirlines(airlineName: string,
    isEnabled: boolean,
    pageSize: number,
    currentPage: number,
    sortByColumn: string,
    ascending: boolean) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('airlineName', airlineName);
    searchParams = searchParams.append('isEnabled', isEnabled);
    
    searchParams = searchParams.append('page', currentPage);
    searchParams = searchParams.append('size', pageSize);
    searchParams = searchParams.append('sort', sortByColumn + "," + (ascending ? 'asc' : 'desc'));
    return this.httpRequestService
      .getWithExplicitParams(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES, searchParams);
  }

  getAirline(airlineId: number) {
    return this.httpRequestService.get(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES + '/' + airlineId)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  getUserAirline(airlineId: number) {
    if (!this.userAirlines$) {
      this.userAirlines$ = this.httpRequestService.get(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES + '/' + airlineId)
        .pipe(shareReplay({ bufferSize: 1, refCount: false, windowTime: HTTP_REQUEST_WINDOW_TIME_CONSTANT  }));
    }
    return this.userAirlines$;
  };

  createAirline(airline: Airline) {
    return this.httpRequestService.post(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES, airline)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  updateAirline(airline: Airline) {
    return this.httpRequestService.put(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES + '/' + airline.airlineNumber, airline)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  getCachedFeaturedHotels(airlineId: number) {
    if (!this.featuredHotels$) {
      this.featuredHotels$ = this.httpRequestService
        .get(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES + '/' + airlineId + '/featuredHotels')
        .pipe(shareReplay({ bufferSize: 1, refCount: false, windowTime: HTTP_REQUEST_WINDOW_TIME_CONSTANT }));
    }
    return this.featuredHotels$;
  };

  getFeaturedHotels(airlineId: number) {
    return this.httpRequestService
      .get(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES + '/' + airlineId + '/featuredHotels')
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  updateFeaturedHotels(featuredHotel: FeaturedHotelUpdateRequest) {
    return this.httpRequestService.put(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES + '/' + featuredHotel.airlineNumber + '/featuredHotels', featuredHotel)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  updateEmailList(featuredHotel: MailListUpdateRequest) {
    return this.httpRequestService.put(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES + '/' + featuredHotel.airlineNumber + '/mailList', featuredHotel)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  getAllFeaturedHotels(
    airline: string,
    pageSize: number,
    currentPage: number,
    sortByColumn: string,
    ascending: boolean) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('airline', airline);
    searchParams = searchParams.append('size', pageSize);
    searchParams = searchParams.append('page', currentPage);
    searchParams = searchParams.append('sort', sortByColumn + "," + (ascending ? 'asc' : 'desc'));
    return this.httpRequestService.getWithExplicitParams(HttpRequestConstants.GET_POST_PUT_DELETE_FEATURED_HOTELS_AND_CITIES, searchParams)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  getAirlineByEnterDomains(emailDomain: string) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('emailDomain', emailDomain);
    return this.httpRequestService.getWithExplicitParams(HttpRequestConstants.GET_POST_PUT_DELETE_AIRLINES_BY_ENTERDOMAINS, searchParams)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

}


export function generateRandomValue(): string {
    let crypto = window.crypto;
    let D = new Uint32Array(2);
    crypto.getRandomValues(D);
    return D[0].toString(36);
};

export function checkPasswordValidity(value: any): string | null {
    const isNonWhiteSpace = /^\S*$/;
    if (!isNonWhiteSpace.test(value)) {
      return "Password must not contain Whitespaces.";
    }

    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    if (!isContainsUppercase.test(value)) {
      return "Password must have at least one Uppercase Character.";
    }

    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsLowercase.test(value)) {
      return "Password must have at least one Lowercase Character.";
    }

    const isContainsNumber = /^(?=.*\d).*$/;
    if (!isContainsNumber.test(value)) {
      return "Password must contain at least one Digit.";
    }

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    if (!isContainsSymbol.test(value)) {
      return "Password must contain at least one Special Symbol.";
    }

    const isValidLength = /^.{8,}$/;
    if (!isValidLength.test(value)) {
      return "Password must be 8 Characters Long.";
    }

    const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (validEmail.test(value)) {
      return "Password must not be contains email address";
    }
    return null;
  }

  export function convertBooleanToString(value: boolean): string {
    return value ? '1' : '0';
  }
  export function getAirlineDateFormat(date: string): string {
    return date.replace('mm','MM');
  }
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const USER_SETTINGS_KEY = 'user-settings';
const SEARCH_FEEDBACKS_KEY = 'search-feedbacks';
const CITY_AND_HOTEL_INFORMATION_KEY = 'city-and-hotel-information';
const TRANSPORT_COMPANIES_KEY = 'transport-companies';
const SEARCH_USERS_KEY = 'search-users';
const SEARCH_SUPPORT_REQUESTS_KEY = 'search-support-requests';
const SEARCH_DAILY_INCIDENT_LOG_KEY = 'search-daily-incident-log';
const FIND_CREW_TRAVEL_KEY = 'find-crew-travel';
const SEARCH_AIRLINES_KEY = 'search-airlines';
const SEARCH_AIRPORTS_KEY = 'search-airports';
const SEARCH_BRANCHES_KEY = 'search-branches';
const SEARCH_FEEDBACK_TOPICS_KEY = 'search-feedback-topics';
const MY_CREW_TRAVEL_KEY = 'my-crew-travel';
const SEARCH_IN_APP_RATINGS_KEY = 'search-in-app-ratings';
const SEARCH_USER_ACTIVITY_HISTORY_KEY = 'user-activity-history';
const SEARCH_MY_TRAVEL_DEMO_KEY = 'search-my-travel-demo';
const SEARCH_EMAIL_TEMPLATES_KEY = 'search-email-templates';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private route: Router) { }
  public clear(): void {
    window.localStorage.clear();
  }
  public saveUserSettings(settings: any): void {
    window.localStorage.removeItem(USER_SETTINGS_KEY);
    window.localStorage.setItem(USER_SETTINGS_KEY, settings);
  }
  public getUserSettings(): any {
    return window.localStorage.getItem(USER_SETTINGS_KEY);
  }
  public saveDarkMode(darkMode:boolean): void {
    let userSettings = JSON.parse(this.getUserSettings());
    userSettings.darkMode = darkMode;
    this.saveUserSettings(JSON.stringify(userSettings));    
  }
  public saveLanguage(language:string): void {
    let userSettings = JSON.parse(this.getUserSettings());
    userSettings.language = language;
    this.saveUserSettings(JSON.stringify(userSettings));    
  }

  public getSearchFeedbacks(): any {
    return window.localStorage.getItem(SEARCH_FEEDBACKS_KEY);
  }
  public saveSearchFeedbacks(settings: any): void {
    window.localStorage.removeItem(SEARCH_FEEDBACKS_KEY);
    window.localStorage.setItem(SEARCH_FEEDBACKS_KEY, settings);
  }
  public getCityAndHotelInformation(): any {
    return window.localStorage.getItem(CITY_AND_HOTEL_INFORMATION_KEY);
  }
  public saveCityAndHotelInformation(settings: any): void {
    window.localStorage.removeItem(CITY_AND_HOTEL_INFORMATION_KEY);
    window.localStorage.setItem(CITY_AND_HOTEL_INFORMATION_KEY, settings);
  }
  public getTransportCompanies(): any {
    return window.localStorage.getItem(TRANSPORT_COMPANIES_KEY);
  }
  public saveTransportCompanies(settings: any): void {
    window.localStorage.removeItem(TRANSPORT_COMPANIES_KEY);
    window.localStorage.setItem(TRANSPORT_COMPANIES_KEY, settings);
  }
  public getSearchUsers(): any {
    return window.localStorage.getItem(SEARCH_USERS_KEY);
  }
  public saveSearchUsers(settings: any): void {
    window.localStorage.removeItem(SEARCH_USERS_KEY);
    window.localStorage.setItem(SEARCH_USERS_KEY, settings);
  }
  public getSupportRequests(): any {
    return window.localStorage.getItem(SEARCH_SUPPORT_REQUESTS_KEY);
  }
  public saveSupportRequests(settings: any): void {
    window.localStorage.removeItem(SEARCH_SUPPORT_REQUESTS_KEY);
    window.localStorage.setItem(SEARCH_SUPPORT_REQUESTS_KEY, settings);
  }
  public getSearchDailyIncidentLog(): any {
    return window.localStorage.getItem(SEARCH_DAILY_INCIDENT_LOG_KEY);
  }
  public saveSearchDailyIncidentLog(settings: any): void {
    window.localStorage.removeItem(SEARCH_DAILY_INCIDENT_LOG_KEY);
    window.localStorage.setItem(SEARCH_DAILY_INCIDENT_LOG_KEY, settings);
  }
  public getFindCrewTravel(): any {
    return window.localStorage.getItem(FIND_CREW_TRAVEL_KEY);
  }
  public saveFindCrewTravel(settings: any): void {
    window.localStorage.removeItem(FIND_CREW_TRAVEL_KEY);
    window.localStorage.setItem(FIND_CREW_TRAVEL_KEY, settings);
  }
  public getSearchAirlines(): any {
    return window.localStorage.getItem(SEARCH_AIRLINES_KEY);
  }
  public saveSearchAirlines(settings: any): void {
    window.localStorage.removeItem(SEARCH_AIRLINES_KEY);
    window.localStorage.setItem(SEARCH_AIRLINES_KEY, settings);
  }
  public getSearchAirports(): any {
    return window.localStorage.getItem(SEARCH_AIRPORTS_KEY);
  }
  public saveSearchAirports(settings: any): void {
    window.localStorage.removeItem(SEARCH_AIRPORTS_KEY);
    window.localStorage.setItem(SEARCH_AIRPORTS_KEY, settings);
  }
  public getSearchBranches(): any {
    return window.localStorage.getItem(SEARCH_BRANCHES_KEY);
  }
  public saveSearchBranches(settings: any): void {
    window.localStorage.removeItem(SEARCH_BRANCHES_KEY);
    window.localStorage.setItem(SEARCH_BRANCHES_KEY, settings);
  }
  public getSearchFeedbackTopics(): any {
    return window.localStorage.getItem(SEARCH_FEEDBACK_TOPICS_KEY);
  }
  public saveSearchFeedbackTopics(settings: any): void {
    window.localStorage.removeItem(SEARCH_FEEDBACK_TOPICS_KEY);
    window.localStorage.setItem(SEARCH_FEEDBACK_TOPICS_KEY, settings);
  }
  public getMyCrewTravel(): any {
    return window.localStorage.getItem(MY_CREW_TRAVEL_KEY);
  }
  public saveMyCrewTravel(settings: any): void {
    window.localStorage.removeItem(MY_CREW_TRAVEL_KEY);
    window.localStorage.setItem(MY_CREW_TRAVEL_KEY, settings);
  }
  public getSearchInAppRatings(): any {
    return window.localStorage.getItem(SEARCH_IN_APP_RATINGS_KEY);
  }
  public saveSearchInAppRatings(settings: any): void {
    window.localStorage.removeItem(SEARCH_IN_APP_RATINGS_KEY);
    window.localStorage.setItem(SEARCH_IN_APP_RATINGS_KEY, settings);
  }
  public getSearchUserActivityHistory(): any {
    return window.localStorage.getItem(SEARCH_USER_ACTIVITY_HISTORY_KEY);
  }
  public setSearchUserActivityHistory(settings: any): void {
    window.localStorage.removeItem(SEARCH_USER_ACTIVITY_HISTORY_KEY);
    window.localStorage.setItem(SEARCH_USER_ACTIVITY_HISTORY_KEY, settings);
  }
  public getSearchMyTravelDemos(): any {
    return window.localStorage.getItem(SEARCH_MY_TRAVEL_DEMO_KEY);
  }
  public saveSearchMyTravelDemos(settings: any): void {
    window.localStorage.removeItem(SEARCH_MY_TRAVEL_DEMO_KEY);
    window.localStorage.setItem(SEARCH_MY_TRAVEL_DEMO_KEY, settings);
  }
  public getSearchEmailTemplates(): any {
    return window.localStorage.getItem(SEARCH_EMAIL_TEMPLATES_KEY);
  }
  public saveSearchEmailTemplates(settings: any): void {
    window.localStorage.removeItem(SEARCH_EMAIL_TEMPLATES_KEY);
    window.localStorage.setItem(SEARCH_EMAIL_TEMPLATES_KEY, settings);
  }
}


import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, shareReplay } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { HttpRequestConstants } from './http-requests-constants';
import { HTTP_REQUEST_WINDOW_TIME_CONSTANT } from '../common/constants/constant';
import { CountryPagedResponse } from '../model/country.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  error = new Subject<string>();  
  allCountries$: Observable<CountryPagedResponse> | undefined;

  constructor(private httpRequestService: HttpRequestService) { }
  
  getAllCountries() {
    if (!this.allCountries$) {
      let searchParams = new HttpParams();
      searchParams = searchParams.append('page', 1);
      searchParams = searchParams.append('size', 10000);
      this.allCountries$ = this.httpRequestService
        .getWithExplicitParams(HttpRequestConstants.GET_POST_PUT_DELETE_COUNTRIES, searchParams)
        .pipe(shareReplay({ bufferSize: 1, refCount: false, windowTime: HTTP_REQUEST_WINDOW_TIME_CONSTANT }));
    }
    return this.allCountries$;
  }

  

}

